<template>
  <v-container fluid>
    <v-row class="justify-center">
      <v-col cols="12" md="9" xl="6">
        <v-card outlined>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12" md="9">
                  <h2>What are groups?</h2>
                  <span>Groups allow you to create badges and issue them to employees.</span>
                </v-col>
                <v-col cols="12" md="3" v-if="hasAdminRole">
                  <v-btn @click="openGroupDialog" text>Create a new group</v-btn>
                </v-col>
              </v-row>
              <v-row justify="center">
                <v-col cols="12" sm="11" md="8">
                  <v-text-field
                      label="Search for groups by name or description"
                      placeholder=""
                      prepend-inner-icon="mdi-magnify"
                      outlined
                      clearable
                      v-model="search"
                      :loading="isLoading"
                    >
                    </v-text-field>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row class="justify-center">
      <v-col cols="12" md="9" xl="6">
        <v-row v-if="isLoading">
          <v-col cols="12" md="4">
          <v-skeleton-loader
              class="pa-2"
              max-height="100"
              min-width="344"
              type="card"
            ></v-skeleton-loader>
          </v-col>
          <v-col cols="12" md="4">
            <v-skeleton-loader
              class="pa-2"
              max-height="100"
              min-width="344"
              type="card"
            ></v-skeleton-loader>
          </v-col>
          <v-col cols="12" md="4">
            <v-skeleton-loader
              class="pa-2"
              max-height="100"
              min-width="344"
              type="card"
            ></v-skeleton-loader>
          </v-col>
          <v-col cols="12" md="4">
            <v-skeleton-loader
              class="pa-2"
              max-height="100"
              min-width="344"
              type="card"
            ></v-skeleton-loader>
          </v-col>
          <v-col cols="12" md="4">
            <v-skeleton-loader
              class="pa-2"
              max-height="100"
              min-width="344"
              type="card"
            ></v-skeleton-loader>
          </v-col>
          <v-col cols="12" md="4">
            <v-skeleton-loader
              class="pa-2"
              max-height="100"
              min-width="344"
              type="card"
            ></v-skeleton-loader>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="4" v-for="(item, index) in groups" :key="index">
            <v-card
              link
              :color="$vuetify.theme.dark ? 'grey darken-4' : ''"
              class="ma-2 pa-1"
              min-height="75"
              :to="'/badges/groups/' + item.name"
              outlined
              hover
              ripple
            >
              <v-list-item>
                <v-list-item-avatar
                  max-height="50"
                  max-width="50"
                >
                  <v-img
                    :src="item.imageBase64"
                    :lazy-src="loadingImage"
                    contain
                  />
                </v-list-item-avatar>
                <v-list-item-content>
                    <v-list-item-title style="font-size: 14pt;">{{ item.displayName }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-card>
          </v-col>
        </v-row>
        <v-row v-if="count > 1">
          <v-col>
            <v-pagination
              v-model="page"
              :length="count"
              circle
            ></v-pagination>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-dialog v-model="showGroupDialog" width="1250">
      <v-card>
        <v-card-title :class="$vuetify.theme.dark ? 'headline grey darken-3' : 'headline grey lighten-3'" @click="openGroupDialog">
          Create a new group
        </v-card-title>

        <v-card-text>
          <v-form ref="form">
            <v-container>
              <v-row>
                <v-col cols="12" md="6">
                  <v-row>
                    <v-col>
                      <v-text-field
                        hint="The name field is provided with the badges to indicate who issued it"
                        v-model="group.displayName"
                        label="Name"
                        :rules="required"
                        required
                      />
                    </v-col>
                  </v-row>
                  <v-row>
                      <v-col cols="12" md="6">
                        <v-combobox
                          v-model="group.tags"
                          hint="Type a tag and press enter. Maximum of 5."
                          :rules="tagRules"
                          label="Tags"
                          multiple
                          persistent-hint
                          small-chips
                        >
                        </v-combobox>
                      </v-col>
                    </v-row>
                  <v-row>
                    <v-col>
                      <v-text-field
                        hint="Optional: A url to your site which has information about your group"
                        v-model="group.url"
                        label="Url"
                      />
                      </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <v-text-field
                        hint="Optional: A telephone number for your group"
                        v-model="group.telephone"
                        label="Phone"
                      />
                      </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <v-text-field
                        hint="Optional: A distribution list that can receive questions about your badges"
                        v-model="group.email"
                        label="Email"
                      />
                      </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <v-textarea
                        hint="The description for your group"
                        v-model="group.description"
                        label="Description"
                        :rules="descriptionRules"
                        required
                      />
                      </v-col>
                  </v-row>
                </v-col>
                <v-col cols="12" md="6">
                  <v-row>
                    <v-col cols="12" md="12">
                      <user-search-component :error="userError" class="required" id="v-step-5" :disabled="isSubmitted" v-model="currentUserSearch" label="Admin Users" />
                      <v-simple-table>
                        <template v-slot:default>
                          <thead>
                            <tr>
                              <th class="text-left">34</th>
                              <th class="text-left">Name</th>
                              <th class="text-left">Delete</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr v-for="user in allowedUsers" :key="user.Key">
                              <td>{{ user.Key }}</td>
                              <td>{{ user.Value }}</td>
                              <v-btn icon @click="deleteUser(user.Key)"><v-icon>mdi-delete</v-icon></v-btn>
                            </tr>
                          </tbody>
                        </template>
                      </v-simple-table>
                    </v-col>
                  </v-row>
                  <v-row v-if="!validated || userError">
                    <v-col>
                      <span v-if="userError" style="color: #ff5252 !important">At least one user is required</span>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            @click="showGroupDialog = false"
            text
          >
            Cancel
          </v-btn>
          <v-btn
            color="primary"
            text
            :disabled="isSubmitted"
            :loading="isSubmitted"
            @click="createGroup"
          >
            Create
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import userSearchComponent from '@/components/userSearchComponent'
import axios from 'axios'
import { mapGetters } from 'vuex'

export default {
  name: 'groups',
  components: {
    'user-search-component': userSearchComponent
  },
  watch: {
    currentUserSearch: function (newValue) {
      if (newValue) {
        if (!this.allowedUsers.find(x => x.Key === newValue.Key)) {
          this.allowedUsers.push(newValue)
        }
      }
    },
    search: function () {
      clearTimeout(this.searchTimeout)

      // Make a new timeout set to go off in 800ms
      this.searchTimeout = setTimeout(() => {
        if (this.search == null) {
          this.search = ''
        }
        this.page = 1
        this.getGroups()
      }, 500)
    },
    page: function () {
      this.getGroups()
    }
  },
  computed: {
    ...mapGetters([
      'oidcUser',
      'badgeUser'
    ]),
    hasAdminRole: {
      get: function () {
        return this.badgeUser.roles.find(x => x === 'SystemAdmin') !== undefined
      }
    }
  },
  data: () => ({
    loadingImage: require('@/assets/circle.png'),
    divisions: [ ],
    currentUserSearch: '',
    isSubmitted: false,
    groups: [ ],
    showGroupDialog: false,
    group: {
      name: ''
    },
    allowedUsers: [ ],
    validated: true,
    userError: false,
    required: [
      v => !!v || 'This field is required',
      v => (v && v.length < 100) || 'must be less than 100 characters'
    ],
    descriptionRules: [
      v => !!v || 'A Description is required',
      v => (v && v.length < 500) || 'must be less than 500 characters'
    ],
    tagRules: [
      v => !v || v.length < 6 || 'Only 5 tags are allowed'
    ],
    isLoading: true,
    page: 1,
    limit: 12,
    count: 0,
    search: ''
  }),
  methods: {
    openGroupDialog: function () {
      this.group = {
        name: ''
      }
      this.showGroupDialog = true
    },
    createGroup: function () {
      this.isSubmitted = true

      if (!this.validate()) {
        this.isSubmitted = false
        return false
      }

      if (this.allowedUsers) {
        this.group.users = [ ]
        this.allowedUsers.forEach(x => {
          this.group.users.push({
            userId: x.Key,
            roles: ['GroupOwner']
          })
        })
      }

      axios(
        `${process.env.VUE_APP_BADGEBASEAPIURL}/groups/`,
        {
          method: 'POST',
          data: JSON.stringify(this.group),
          headers: { 'Content-Type': 'application/json' }
        })
        .then(
          (response) => {
            this.isSubmitted = false
            this.$router.push(`/badges/groups/${response.data.id}`)
          },
          (error) => {
            this.isSubmitted = false
            this.$store.commit('showErrorDialog', { errorData: error, persistErrorDialog: false, retryMethod: () => this.createGroup() })
            return error
          })
    },
    validate: function () {
      this.validated = true
      this.userError = false
      if (this.allowedUsers.length <= 0) {
        this.userError = true
        this.validated = false
      }

      if (this.pathError) {
        this.validated = false
      }

      if (!this.$refs.form.validate()) {
        this.validated = false
      }
      return this.validated
    },
    deleteUser: function (username) {
      this.allowedUsers = this.allowedUsers.filter((item) => {
        return item.Key !== username
      })
    },
    getGroups: function () {
      this.isLoading = true
      axios(
        `${process.env.VUE_APP_BADGEBASEAPIURL}/groups?showOwnedGroupsOnly=true&page=${this.page}&limit=${this.limit}&search=${this.search}`,
        {
          method: 'GET',
          headers: { 'Content-Type': 'application/json' }
        })
        .then(
          (response) => {
            this.isLoading = false
            this.groups = response.data.items
            this.count = Math.ceil(response.data.totalCount / this.limit)

            this.groups.forEach(x => {
              this.getImage(x)
            })
          },
          (error) => {
            this.$store.commit('showErrorDialog', { errorData: error, persistErrorDialog: true, retryMethod: () => this.getGroups() })
          })
    },
    getDivisions: function () {
      this.isLoading = true
      axios(
        `${process.env.VUE_APP_BADGEBASEAPIURL}/divisions`,
        {
          method: 'GET',
          headers: { 'Content-Type': 'application/json' }
        })
        .then(
          (response) => {
            this.divisions = response.data.divisions ? response.data.divisions : [ ]
          },
          (error) => {
            this.$store.commit('showErrorDialog', { errorData: error, persistErrorDialog: true, retryMethod: () => this.getDivisions() })
          })
    },
    getImage: function (group) {
      if (group.image) {
        return axios(
          `${process.env.VUE_APP_BADGEBASEAPIURL}/images/${group.image.id}`,
          {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' }
          })
          .then(
            (response) => {
              group.imageBase64 = response.data.base64Image
              this.$forceUpdate()
            },
            (error) => {
              this.$store.commit('showErrorDialog', { errorData: error, persistErrorDialog: true, retryMethod: () => this.getImage() })
            })
      }
    }
  },
  mounted: function () {
    this.allowedUsers.push({
      Key: this.oidcUser.sub,
      Value: this.oidcUser.displayName
    })
    this.getDivisions()
    this.getGroups()

    this.$store.commit('breadcrumbs', [
      {
        text: 'My Groups',
        exact: true,
        disabled: false,
        to: '/badges/groups'
      }
    ])
  }
}
</script>

<style scoped>
  .font-bold {
    font-weight: bold;
  }

  .tab-item-wrapper {
    /* vuetify sets the v-tabs__container height to 48px */
    min-height: calc(100vh - 220px)
  }
</style>
