<template>
  <v-container>
    <v-row class="justify-center">
      <v-col cols="12" md="12" xl="12">
        <v-card outlined>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12" md="12">
                  <h2><a @click="changeUser">{{ userDetails.FirstName }} {{ userDetails.LastName }}{{ userDetails.LastName.endsWith('s') ? '\'' : '\'s' }} badges</a></h2>
                  <span>The following list consists of badges that have been awarded to {{ userDetails.FirstName }}. Click on the person's name above to find other employees.</span>
                </v-col>
              </v-row>
              <v-row justify="center">
                <v-col cols="12" sm="11" md="8">
                  <v-text-field
                    label="Search for badges by name, description, or tag"
                    placeholder=""
                    prepend-inner-icon="mdi-magnify"
                    outlined
                    clearable
                    v-model="search"
                    :loading="isLoading"
                  >
                  </v-text-field>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row v-if="assertions.length > 0">
      <v-col cols="12" md="4" v-for="(item, index) in assertions" :key="index">
         <v-card
            @click="routeToAssertion(item.id)"
            :color="$vuetify.theme.dark ? 'grey darken-4' : ''"
            class="ma-2 pa-1"
            outlined
            hover
            ripple
          >
            <v-img
              contain
              max-height="150"
              style="height: 150px"
              :transition="false"
              :src="item.imageBase64"
              :lazy-src="badgeLoadingImage"
              class="ma-2 pt-2"
            ></v-img>

            <v-card-subtitle class="d-flex justify-center">{{ item.badgeDisplayName }}</v-card-subtitle>
         </v-card>
      </v-col>
    </v-row>
    <v-row v-else>
      <v-col class="text-center">
        <span>No badges were found</span>
      </v-col>
    </v-row>
    <v-row v-if="count > 1">
      <v-col>
        <v-pagination
          v-model="page"
          :length="count"
          circle
        ></v-pagination>
      </v-col>
    </v-row>
    <v-dialog v-model="showUserDialog" width="500">
      <v-card>
        <v-card-title>Change user</v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col>
                <span class="text-subtitle-1">Type in a user's 34 or last name below to search for them</span>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <user-search-component class="required" v-model="currentUserSearch" :no-clear="true" label="HCA User" />
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn text @click="showUserDialog = false">Close</v-btn>
          <v-btn text :disabled="!newUser" @click="routeToUser">View User's badges</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import axios from 'axios'
import { mapGetters } from 'vuex'
import userSearchComponent from '@/components/userSearchComponent'

export default {
  name: 'BadgeList',
  components: {
    'user-search-component': userSearchComponent
  },
  watch: {
    search: function () {
      clearTimeout(this.searchTimeout)

      // Make a new timeout set to go off in 800ms
      this.searchTimeout = setTimeout(() => {
        if (this.search == null) {
          this.search = ''
        }
        this.page = 1
        this.getUserBadges()
      }, 500)
    },
    page: function () {
      this.getUserBadges()
    },
    currentUserSearch: function (newValue, oldValue) {
      if (newValue) {
        this.newUser = newValue.Key
      }
    }
  },
  computed: {
    ...mapGetters([
      'oidcUser',
      'badgeUser'
    ]),
    hasAdminRole: {
      get: function () {
        return this.badgeUser.roles.find(x => x === 'SystemAdmin') !== undefined
      }
    },
    userDisplayName: {
      get: function () {
        return this.userDetails.FirstName + ' ' + this.userDetails.LastName + this.userDetails.LastName.endsWith('s') ? '\'' : '\'s'
      }
    }
  },
  data: () => ({
    showUserDialog: false,
    newUser: '',
    currentUserSearch: null,
    userId: null,
    userDetails: {
      FistName: '',
      LastName: ''
    },
    isDeleting: false,
    badgeLoadingImage: require('@/assets/defaultBadge.svg'),
    assertions: [ ],
    isLoading: true,
    page: 1,
    limit: 12,
    count: 0,
    search: '',
    searchTimeout: null
  }),
  methods: {
    changeUser: function () {
      this.showUserDialog = true
    },
    routeToUser: function () {
      window.location = `/badges/user/${this.newUser}`
    },
    routeToAssertion: function (assertionId) {
      this.$router.push(`/badges/assertions/${assertionId}`)
    },
    getUserBadges: function () {
      this.isLoading = true
      axios(
        `${process.env.VUE_APP_BADGEBASEAPIURL}/users/${this.userId}/assertions?page=${this.page}&limit=${this.limit}&search=${this.search}`,
        {
          method: 'GET',
          headers: { 'Content-Type': 'application/json' }
        })
        .then(
          (response) => {
            this.isLoading = false
            this.assertions = response.data.items
            this.count = Math.ceil(response.data.totalCount / this.limit)

            this.assertions.forEach(x => {
              this.getImage(x)
            })
          },
          (error) => {
            this.$store.commit('showErrorDialog', { errorData: error, persistErrorDialog: true, retryMethod: () => this.getUserBadges() })
          })
    },
    getImage: function (assertion) {
      return axios(
        `${process.env.VUE_APP_BADGEBASEAPIURL}/badges/${assertion.badgeId}/image`,
        {
          method: 'GET',
          headers: { 'Content-Type': 'application/json' }
        })
        .then(
          (response) => {
            assertion.imageBase64 = response.data.base64Image
            this.$forceUpdate()
          },
          (error) => {
            this.$store.commit('showErrorDialog', { errorData: error, persistErrorDialog: true, retryMethod: () => this.getImage() })
          })
    },
    getUserDetails: async function () {
      return axios(
        `${process.env.VUE_APP_BASENETWORKAPIURL}/activedirectory/user/${this.userId}`,
        {
          method: 'GET',
          headers: { 'Content-Type': 'application/json' }
        })
        .then(
          (response) => {
            this.userDetails = response.data
          },
          (error) => {
            this.$store.commit('showErrorDialog', { errorData: error, persistErrorDialog: false, retryMethod: () => this.getUserDetails() })
          })
    }
  },
  mounted: function () {
    if (this.$route.params.username) {
      this.userId = this.$route.params.username
      this.getUserDetails().then(() => {
        this.$store.commit('breadcrumbs', [
          {
            text: 'Badges',
            exact: true,
            disabled: false,
            to: '/badges'
          },
          {
            text: this.userDetails.FirstName + ' ' + this.userDetails.LastName,
            exact: true,
            disabled: false,
            to: `/badges/user/${this.userDetails.Username}`
          }
        ])
      })
      this.getUserBadges()
    } else {
      alert('could not find user id in url path')
    }
  }
}
</script>

<style scoped>
.v-window__container {
    height: 100% !important;
  }
</style>
